import React from "react"
import Layout from "../components/layout"
import { FormattedMessage } from 'react-intl'
import SEO from "../components/seo"

const SignUpPage = ({ pathContext: { locale } }) => (
    <Layout locale={locale} signup={true}>
        <SEO title="Ilmoittaudu pilottiin"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1><FormattedMessage id="Sign up" /></h1>
                </div>
                <div className={"row"}>
                    <div className={"col-9"}>
                        <p><FormattedMessage id="SignUpPitch" /></p>
                        <div className="sign-up-form">
                            <form name='RekoForm' method='post' data-netlify='true' action="/thank-you">
                                <input type='hidden' name='form-name' />
                                <label><FormattedMessage id="Your Email" />:</label>
                                <input type='email' name='email' />
                                <br />
                                <label><FormattedMessage id="Your Name" />:</label>
                                <input type='text' name='name' />
                                <br />
                                <label><FormattedMessage id="eCommerce URL" />:</label>
                                <input type='text' name='text' />
                                <br />
                                <label><FormattedMessage id="eCommerce Platform" />:</label>
                                <input type='text' name='platform' />
                                <br />
                                <label><FormattedMessage id="Message" />:</label>
                                <textarea name='message' />
                                <br />
                                <button type='submit' className='button' ><FormattedMessage id="Send" /></button>
                            </form>
                        </div>
                    </div>
                    <div className={"col-3"}>
                        <div className={"contact-items"}>
                            <p><FormattedMessage id="HelpText" /></p>
                            <h4>info@reko.ai</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default SignUpPage
